<style scoped>
.job-tbl .b-table-sticky-header {
    max-height: 500px;
}
</style>
<template>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">Reports</div>
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-3 bg-dark rounded">
                            <div class="container p-2 text-center">
                                <label
                                    for="invoice_no"
                                    class="text-light fw-bold fs-4"
                                    >Filter</label
                                >
                                <div class="text-start pt-3">
                                    <label
                                        for="invoice_number"
                                        class="text-light"
                                        >Invoice No</label
                                    >
                                    <input
                                        type="text"
                                        name="invoice_number"
                                        id="invoice_number"
                                        class="form-control mb-2"
                                        placeholder="XXXXX"
                                        v-model="form.invoice_unique"
                                    />

                                    <label for="job_number" class="text-light"
                                        >Job No</label
                                    >
                                    <input
                                        type="text"
                                        name="job_number"
                                        id="job_number"
                                        class="form-control mb-2"
                                        placeholder="XXXXX"
                                        v-model="form.job_unique"
                                    />

                                    <label
                                        for="invoice_status"
                                        class="text-light"
                                        >Invoice Status</label
                                    >
                                    <select
                                        id="invoice_status"
                                        class="form-select mb-2"
                                        aria-label="invoice_status"
                                        v-model="form.invoice_status"
                                    >
                                        <option selecte value="all">All</option>
                                        <option value="completed">
                                            Completed
                                        </option>
                                        <option value="due">Due</option>
                                    </select>

                                    <label for="job_status" class="text-light"
                                        >Job Status</label
                                    >
                                    <select
                                        id="job_status"
                                        class="form-select mb-2"
                                        aria-label="job_status"
                                        v-model="form.job_status"
                                    >
                                        <option selected value="all">
                                            All
                                        </option>
                                        <option value="completed">
                                            Completed
                                        </option>
                                        <option value="pending">Pending</option>
                                    </select>

                                    <label for="from_date" class="text-light"
                                        >From</label
                                    >
                                    <input
                                        type="date"
                                        name="from_date"
                                        id="from_date"
                                        class="form-control mb-2"
                                        v-model="form.from_date"
                                    />

                                    <label for="to_date" class="text-light"
                                        >To</label
                                    >
                                    <input
                                        type="date"
                                        name="to_date"
                                        id="to_date"
                                        class="form-control mb-2"
                                        v-model="form.to_date"
                                    />

                                    <label
                                        for="customer_name"
                                        class="text-light"
                                        >Customer</label
                                    >
                                    <div class="d-flex">
                                        <multiselect
                                            v-model="form.customer"
                                            tag-placeholder="Insert name"
                                            placeholder="Search name"
                                            label="name"
                                            track-by="id"
                                            :options="customers"
                                            :multiple="false"
                                            :taggable="true"
                                            @search-change="getCustomers"
                                        ></multiselect>
                                    </div>

                                    <div
                                        class="d-flex justify-content-between pt-4 pb-4"
                                    >
                                        <Button
                                            class="btn btn-light w-100 me-2"
                                            @click="resetForm()"
                                        >
                                            Reset</Button
                                        >
                                        <Button
                                            class="btn btn-primary w-100"
                                            @click="getReports()"
                                        >
                                            Refresh</Button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <nav>
                                <div
                                    class="nav nav-tabs"
                                    id="nav-tab"
                                    role="tablist"
                                    style="border-bottom: 0px"
                                >
                                    <button
                                        class="nav-link active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true"
                                    >
                                        Jobs
                                    </button>
                                    <button
                                        class="nav-link"
                                        id="nav-profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected="false"
                                        v-if="admin"
                                    >
                                        Invoices
                                    </button>
                                    <button
                                        class="nav-link"
                                        id="nav-payments-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-payments"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-payments"
                                        aria-selected="false"
                                        v-if="admin"
                                    >
                                        Payments
                                    </button>
                                </div>
                            </nav>
                            <div
                                class="tab-content border p-2 pt-3"
                                id="nav-tabContent"
                            >
                                <div
                                    class="tab-pane fade show active"
                                    style="height: 350px"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                >
                                    <div class="row">
                                        <div class="col-12 job-tbl">
                                            <b-table
                                                small
                                                striped
                                                hover
                                                :fields="JobDatafields"
                                                :items="JobData"
                                                responsive="sm"
                                                show-empty
                                                :empty-text="'There are no related jobs to show'"
                                                class="mt-4"
                                                sticky-header
                                            >
                                                <template #empty="scope">
                                                    <p class="text-center mt-3">
                                                        {{ scope.emptyText }}
                                                    </p>
                                                </template>
                                                <template
                                                    #emptyfiltered="scope"
                                                >
                                                    <p class="text-center mt-3">
                                                        {{
                                                            scope.emptyFilteredText
                                                        }}
                                                    </p>
                                                </template>

                                                <template
                                                    #cell(job_unique)="data"
                                                >
                                                    <a
                                                        :href="
                                                            '/job/' +
                                                            data.item.job_unique
                                                        "
                                                        >{{
                                                            data.item.job_unique
                                                        }}</a
                                                    >
                                                </template>

                                                <template
                                                    #cell(invoice_unique)="data"
                                                >
                                                    <a
                                                        :href="
                                                            '/invoice/' +
                                                            data.item
                                                                .invoice_unique
                                                        "
                                                        v-if="
                                                            data.item.type !=
                                                            'temp'
                                                        "
                                                        >{{
                                                            data.item
                                                                .invoice_unique
                                                        }}</a
                                                    >
                                                    <span v-else> - </span>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="nav-profile"
                                    style="height: 350px"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    v-if="admin"
                                >
                                    <div class="row">
                                        <div class="col-12">
                                            <b-table
                                                small
                                                striped
                                                hover
                                                :fields="InvoiceDatafields"
                                                :items="InvoiceData"
                                                responsive="sm"
                                                show-empty
                                                :empty-text="'There are no related invoices to show'"
                                                class="mt-4"
                                                sticky-header
                                            >
                                                <template #empty="scope">
                                                    <p class="text-center mt-3">
                                                        {{ scope.emptyText }}
                                                    </p>
                                                </template>
                                                <template
                                                    #emptyfiltered="scope"
                                                >
                                                    <p class="text-center mt-3">
                                                        {{
                                                            scope.emptyFilteredText
                                                        }}
                                                    </p>
                                                </template>

                                                <template
                                                    #cell(invoice_unique)="data"
                                                >
                                                    <a
                                                        :href="
                                                            '/invoice/' +
                                                            data.item
                                                                .invoice_unique
                                                        "
                                                        >{{
                                                            data.item
                                                                .invoice_unique
                                                        }}</a
                                                    >
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>

                                    <div class="row p-2 bg-light">
                                        <div class="col"></div>
                                        <div class="col">
                                            <label for="t_amount">Amount</label>
                                            <input
                                                type="text"
                                                name="t_amount"
                                                id="t_amount"
                                                class="form-control mb-2 text-end"
                                                placeholder="0.00"
                                                disabled
                                                v-model="summery.total"
                                            />
                                        </div>
                                        <div class="col">
                                            <label for="paid_amo"
                                                >Paid Amount</label
                                            >
                                            <input
                                                type="text"
                                                name="paid_amo"
                                                id="paid_amo"
                                                class="form-control mb-2 text-end"
                                                placeholder="0.00"
                                                disabled
                                                v-model="summery.paid"
                                            />
                                        </div>
                                        <div class="col">
                                            <label for="t_balance"
                                                >Balance</label
                                            >
                                            <input
                                                type="text"
                                                name="t_balance"
                                                id="t_balance"
                                                class="form-control mb-2 text-end"
                                                placeholder="0.00"
                                                disabled
                                                v-model="summery.balance"
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <p
                                                class="mb-1 mt-1 bg-dark text-center text-light"
                                            >
                                                Today so far
                                            </p>

                                            <div class="container bg-light">
                                                <div class="row p-2">
                                                    <div class="col">
                                                        <label
                                                            for="invoices"
                                                            class="fw-bold"
                                                            >Invoices</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="invoices"
                                                            id="invoices"
                                                            class="form-control mb-2 text-end"
                                                            placeholder="0.00"
                                                            disabled
                                                            v-model="
                                                                today_so_far.invoice
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <label
                                                            for="job_advance"
                                                            class="fw-bold"
                                                            >Job Advances</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="job_advance"
                                                            id="job_advance"
                                                            class="form-control mb-2 text-end"
                                                            placeholder="0.00"
                                                            disabled
                                                            v-model="
                                                                today_so_far.job_advance
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <label
                                                            for="other_payment"
                                                            class="fw-bold"
                                                            >Other
                                                            Payments</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="other_payment"
                                                            id="other_payment"
                                                            class="form-control mb-2 text-end"
                                                            placeholder="0.00"
                                                            disabled
                                                            v-model="
                                                                today_so_far.other_payments
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <label
                                                            for="total"
                                                            class="fw-bold"
                                                            >Total</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="total"
                                                            id="total"
                                                            class="form-control mb-2 text-end fw-bolder"
                                                            placeholder="0.00"
                                                            disabled
                                                            v-model="
                                                                today_so_far.total
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--start payments-->
                                <div
                                    class="tab-pane fade"
                                    style="height: 350px"
                                    id="nav-payments"
                                    role="tabpanel"
                                    aria-labelledby="nav-payments-tab"
                                >
                                    <div class="row">
                                        <div class="col-12 job-tbl">
                                            <b-table
                                                small
                                                striped
                                                hover
                                                :fields="PaymentDatafields"
                                                :items="PaymentData"
                                                responsive="sm"
                                                show-empty
                                                :empty-text="'There are no related payments to show'"
                                                class="mt-4"
                                                sticky-header
                                            >
                                                <template #empty="scope">
                                                    <p class="text-center mt-3">
                                                        {{ scope.emptyText }}
                                                    </p>
                                                </template>
                                                <template
                                                    #emptyfiltered="scope"
                                                >
                                                    <p class="text-center mt-3">
                                                        {{
                                                            scope.emptyFilteredText
                                                        }}
                                                    </p>
                                                </template>

                                                <template
                                                    #cell(job_unique)="data"
                                                >
                                                    <a
                                                        :href="
                                                            '/job/' +
                                                            data.item.job_unique
                                                        "
                                                        >{{
                                                            data.item.job_unique
                                                        }}</a
                                                    >
                                                </template>

                                                <template
                                                    #cell(invoice_unique)="data"
                                                >
                                                    <a
                                                        :href="
                                                            '/invoice/' +
                                                            data.item
                                                                .invoice_unique
                                                        "
                                                        v-if="
                                                            data.item.type !=
                                                            'temp'
                                                        "
                                                        >{{
                                                            data.item
                                                                .invoice_unique
                                                        }}</a
                                                    >
                                                    <span v-else> - </span>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                                <!--end payments-->



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: { Multiselect },
    props: [],
    data() {
        return {
            form: {
                invoice_unique: null,
                job_unique: null,
                invoice_status: null,
                job_status: null,
                from_date: null,
                to_date: null,
                customer: null,
            },
            JobData: [],
            JobDatafields: [
                { key: "date_time", label: "Date" },
                { key: "job_unique", label: "Job" },
                { key: "invoice_unique", label: "Invoice" },
                { key: "customer", label: "Customer" },
                { key: "description", label: "Description" },
                { key: "job_status", label: "Job status" },
            ],
            InvoiceData: [],
            InvoiceDatafields: [
                { key: "date_time", label: "Date" },
                { key: "invoice_unique", label: "Invoice" },
                { key: "customer", label: "Customer" },
                { key: "total", label: "Amount" },
                { key: "paid", label: "Paid amount" },
                { key: "balance", label: "Balance amount" },
            ],
            PaymentDatafields: [
                { key: "date_time", label: "Date" },
                { key: "job_unique", label: "Job" },
                { key: "invoice_unique", label: "Invoice" },
                { key: "customer", label: "Customer" }, 
                { key: "amount", label: "Amount" },
            ],
            PaymentData: [],
            customers: [],
            summery: {
                balance: 0,
                paid: 0,
                total: 0,
            },
            today_so_far: {
                invoice: 0,
                job_advance: 0,
                other_payments: 0,
                total: 0,
            },
            admin: false,
        };
    },
    computed: {},
    created() {
        this.setDefaultFilters();
        this.getCustomers();
        this.getReports();
    },
    methods: {
        resetForm() {
            this.form = {
                invoice_unique: null,
                job_unique: null,
                invoice_status: null,
                job_status: null,
                from_date: null,
                to_date: null,
                customer: null,
            };
            this.setDefaultFilters();
            this.getReports();
        },
        setDefaultFilters() {
            let currentDate = moment();
            let currentDateString = currentDate.format("YYYY-MM-DD");

            let oneMonthAgo = moment().subtract(1, "months");
            let oneMonthAgoString = oneMonthAgo.format("YYYY-MM-DD");

            this.form.from_date = oneMonthAgoString;
            this.form.to_date = currentDateString;

            this.form.invoice_status = "due";
            this.form.job_status = "pending";
        },
        getReports() {
            axios.post("/j/get-reports", this.form).then((response) => {
                if (response.data.status) {
                    this.summery = response.data.info.summery;
                    this.today_so_far = response.data.info.today_so_far;

                    this.JobData = response.data.info.jobs;
                    this.InvoiceData = response.data.info.invoices;
                    this.PaymentData = response.data.info.payments;
                    this.admin = response.data.role;
                }
            });
        },
        getCustomers(query) {
            axios.post("/j/customers", { search: query }).then((response) => {
                if (response.data.data) {
                    this.customers = response.data.data;
                }
            });
        },
    },
    watch: {},
};
</script>
